<template>
  <div class="px-1">
    <div class="mb-2">
      <filter-swapper :controlHeightButtons="controlHeight">
        <template #slot1>
          <form-render :key="keyFormRender" :form.sync="formFilter" :fields="fields" @send="simpleFilter" :buttonSend="buttonSend" ref="formFilterDeliveries"
            containerButtonsClass="col-sm-12 col-md-6 container-button mt-2" class="mb-1">
            <template #buttons>
              <b-dropdown class="mx-1" v-b-tooltip.hover :title="$t('Más opciones')" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="light"> 
                <template #button-content><feather-icon icon="SettingsIcon"/></template>
                <b-dropdown-item @click="cleanForm">{{$t('Limpiar filtros')}}</b-dropdown-item>
                <b-dropdown-item v-b-modal.modalAvancedFilterDeliveries>{{$t('Búsqueda avanzada')}}</b-dropdown-item>
              </b-dropdown>
              <b-button variant="warning" @click="openModal('modalMultiSearch')">
                {{$t('Multiselección')}}
              </b-button>
              <b-form-checkbox class="custom-switch-success push-right" :checked="formFilter.sameday" switch v-model="formFilter.sameday" @change="filterForm(formFilter)"> {{$t('Servicio Sameday')}}</b-form-checkbox>
            </template>
          </form-render>
        </template>
      </filter-swapper>
      <b-button variant="outline-secondary" v-show="!blockDeleteDeliveries" @click="deleteDeliveries" v-b-tooltip.hover :title="$t('Eliminar todos los envíos')" :disabled="!selectedRows.length">
        <feather-icon icon="Trash2Icon" size="16"/>
      </b-button>
      <b-button variant="warning" @click="putlabel" v-b-tooltip.hover :title="$t('Generar etiqueta')"  class="mx-1" :disabled="!selectedRows.length || loadDispacher">
        <b-spinner v-show="loadDispacher" small></b-spinner>&nbsp;<feather-icon icon="TagIcon" size="16"/>
      </b-button>
      <span><b>{{ selectedRows.length }} envíos seleccionados</b></span>
    </div>

    <div v-show="!loading.first" class="px-1">
      <table-render id="table-deliveries-labels"
        :schema="schema"
        :rows.sync="rows"
        :selectedRows.sync="selectedRows"
        showCheckboxes="true"
        :loading="loading.deliveries"
        :sortByCustom="orderBy"
        :actions="actions"
      >
        <template #current_status="scope">
          <span :class="`table_dot--state table_dot--state-${is[statusType]}`"></span> {{scope.rowdata.status_name}}
        </template>
        <template #imported_id="scope">
          <a class="p-0 underline-text" :href="`${environment}/beta/deliveries/${scope.rowdata.id}`" target="_blank">{{scope.rowdata.imported_id}}</a>
        </template>
        <template #tracking_number="scope">
          {{scope.rowdata.tracking_number || "sin info."}}
        </template>
        <template #customer="scope">
          <span v-if="!scope.rowdata.customer_name">Sin info.</span>
          <a v-else v-b-tooltip.click.html="scope.rowdata.client_information" class="lnk underline-text">{{scope.rowdata.customer_name}}</a>
        </template>
        <template #place_name="scope">
          {{[2,3,4,5,6].includes(scope.rowdata.country_id) ? (scope.rowdata.place_level_1_name+', ' || '')+scope.rowdata.place_name : scope.rowdata.place_name}}
        </template>
        <template #pickup_date="scope">
          {{scope.rowdata.pickup_date || "sin info."}}
        </template>
        <template #created_at="scope">
          {{scope.rowdata.created_country_timezone_formatted || scope.rowdata.created_at_formatted}}
        </template>
      </table-render>
      <pagination
        :pagination="pagination"
        :noDigits="true"
        :showSize="true"
      />
    </div>
    <b-skeleton type="input" v-if="loading.first" class="mb-2 mt-2 spacing-label-field"/>
    <div class="table-render-skeleton px-1" v-if="loading.first">
      <b-skeleton-table
        :rows="pagination.deliveries || 10"
        :columns="schema.length || 10"
        :table-props="{ }"/>
    </div>
    <modal-avanced-filter @filter="collectFullFilter" @showAlert="showAlert" :date="formFilter.date_range" :countries="countries" ref="modalAvancedFilterDeliveries"></modal-avanced-filter>
    <modal-multi-search @filter="collectFullFilter" ref="refModalSearch"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { environment } from '@/environments/environment'
import ModalAvancedFilter from '../ModalAvancedFilter.vue'
import ModalMultiSearch from './ModalMultiSearch.vue'


export default {
  props: ['typeName', 'changeTab'],
  components: { ModalAvancedFilter, ModalMultiSearch },
  data () {
    return {
      controlHeight: { class: 'row spacing-label-field'},
      fields: [
        { fieldType: 'FieldDatepicker', name: 'date_range', label: this.$t('Fecha'), range: true, containerClass: 'col-sm-12 col-md-3', propsVCalendar: { 'max-date': new Date() } },
        { fieldType: 'FieldInput', label: this.$t('N. de envío'), name: 'number', placeholder: this.$t('Buscar envío'), containerClass: 'col-sm-12 col-md-3' }
      ],
      initialDateRange: { start: this.$options.filters.momentSubtract(35), end: this.$options.filters.momentAdd(7) },
      buttonSend: {title: 'Buscar', icon: 'SearchIcon', color: 'warning'},
      selectedRows: [],
      actions: [],
      loadDispacher: false,
      schema: [],
      rows: [],
      formFilter: {},
      pagination: {
        page: 1,
        limit: 20,
        total: 20
      },
      keyFormRender: 0,
      loading: {
        first: true,
        deliveries: false
      },
      countryCurrency: '$',
      show: {
        exportButton: false
      },
      switchButtons: {
        sameday: false
      },
      statusType: 'loaded',
      userData: null,
      firstCall: true,
      blockDeleteDeliveries: true,
      is: {
        loaded: 'creado',
        rejected: 'incidence'
      }
    }
  },
  computed: {
    ...mapGetters({
      countries: 'getCountries',
      deliveriesPlat: 'getDeliveriesPlat'
    })
  },
  mounted () {
    this.initialLoad()
    this.firstCall = false
  },
  watch: {
    deliveriesPlat () {
      this.rows = this.deliveriesPlat.rows.map(data => ({
        ...data,
        client_information: `${data.customer_email || data.customer_phone ? `${data.customer_email ? `<strong>Email:</strong> ${data.customer_email}</br>` : ''}${data.customer_phone ? `<strong>Teléfono:</strong> ${data.customer_phone}` : ''}` : 'Sin info.'}`
      }))
      this.loading.first = false
      this.loading.deliveries = false
      // this.pagination.page = Number(this.deliveriesPlat.pagination.page)
      // this.pagination.total = Number(this.deliveriesPlat.pagination.total)
      // this['pagination.limit'] = Number(this.deliveriesPlat.pagination.pages)
      this.$bvModal.hide('modalAvancedFilterDeliveries')
      this.selectedRows = []
    },
    'pagination.page'() {
      this.getDeliveries()
    },
    'pagination.limit'() {
      if (this.pagination.page === 1) this.getDeliveries()
    }
  },
  methods: {
    setSelectOptions (name, { options }) {
      const index = this.fields.findIndex(el => el.name === name)
      if (index !== -1) {
        this.fields[index].options = options
        this.keyFormRender++
      }
      this.formFilter[name] = null
    },
    initialLoad () {
      if (this.typeName) this.statusType = this.typeName
      //OJO validar el blockDeleteDeliveries con permisos de usuario -> aun no existe
      this.userData = this.$session.get('cas_user')
      this.setInitialData()
      this.filterList(this.formFilter)
    },
    setInitialData () {
      this.blockDeleteDeliveries = this.userData?.user_access?.block_delete_deliveries
      this.formFilter = {
        date_range: this.initialDateRange,
        sameday: false
      }
      this.schema = [
        {label: this.$t('Estado'), key: 'current_status', useSlot: true},
        {label: this.$t('N. de envío'), key: 'imported_id', useSlot: true, customSort: 'imported_id'},
        {label: this.$t('Cliente'), key: 'customer', useSlot: true},
        {label: this.$t('Comuna'), key: 'place_name', useSlot: true},        
        {label: this.$t('Courier'), key: 'carrier_name', wrap: true},
        {label: this.$t('Fecha creación'), key: 'created_at', useSlot: true, wrap: true}
      ]
      if (this.statusType === 'loaded') {
        this.schema.push({ label: 'Acciones', key: 'actions', class: ['text-center'] })
        this.actions = [
          { action: id => this.confirmeDelete(id), icon: 'TrashIcon', color: 'danger', text: 'Eliminar' }
        ]
      }
      if (['marketplace', 'superadmin', 'admin'].includes(this.$session.get('cas_user')?.role)) {
        this.schema.unshift({label: this.$t('Comercio'), key: 'company_name'})
      }
      if ([10, 1162].includes(this.$session.get('cas_user')?.dubai_organization_id)) {
        this.schema.splice(this.getElementIndex(this.schema, 'imported_id') + 1, 0, {label: this.$t('Fecha de retiro'), key: 'pickup_date', customSort: 'pickup_date'})
      }
      this.changeTextI18n(this.$session.get('cas_user')?.country.code, 'place_name', 'Place_id')
     
      this.environment = environment.platformUrl
    },
    filterForm (form) {
      this.filterList({...this.advancedFilters, ...form})
    },
    filterList (data, isExportation = false) {
      this.filters = {}
      Object.keys(data).map(key => {
        if (key !== 'date_range') {
          if (key === 'company') {
            if (this.$refs.modalAvancedFilterDeliveries.setIsMarketplace()) {
              this.filters.marketplace = Number(data[key]?.id)
            } else {
              this.filters.company = Number(data[key]?.id)
            }
          }
          else if (['carrier', 'pudo', 'country', 'place', 'place_id', 'type', 'multi_package'].includes(key)) this.filters[key] = Number(data[key]?.id)
          else if (key === 'service') this.filters[key] = data[key]?.code
          else if (key === 'place_level_1') this.filters[key] = data[key]?.name
          else if (key === 'flow_action') this.filters[key] = data[key].map(action => action.flow_action).join('-')
          else if (key === 'contract_type') this.filters[key] = data[key].map(action => action.id).join(',')
          else if (key === 'sellers') this.filters[key] = data[key].map(data => Number(data.id))
          else if (['number', 'email'].includes(key)) this.filters[key] = data[key]
          else if (['sameday', 'later_status'].includes(key) && data[key]) this.filters[key] = 1
          if (data.service?.code && data.carrier?.dispatch_in) {
            this.filters.dispatch_in = data.carrier.dispatch_in
            this.filters.service = (data.carrier.dispatch_in === 'akihabara') ? data.service.code : data.service.id
          }
        } else {
          this.filters.origin_created_at_from = this.$options.filters.moment(data.date_range.start, 'YYYY-MM-DD')
          this.filters.origin_created_at_to = this.$options.filters.moment(data.date_range.end, 'YYYY-MM-DD')
        }
      })
      if (!isExportation) { 
        this.pagination.page !== 1 ? this.pagination.page = 1 : this.getDeliveries()
      } else {
        //Se llama desde aqui para poder tomar el dato del filtro simple tambien
        this.callExportFn()
      }
    },
    callExportFn() {
      this.$refs.modalAvancedFilterDeliveries.exportData(this.filters)
    },
    showAlert (msg, des, type) {
      this.$bvToast.toast(msg, {
        title: des,
        variant: type,
        solid: true
      })
    },
    simpleFilter() {
      this.pagination.page > 1 ? this.pagination.page = 1 : this.filterList(this.formFilter)
    },  
    getDeliveries () {
      this.loading.deliveries = true
      const queryParams = {
        ...this.filters,
        status: 'pending',
        tab: this.statusType,
        calls: this.pagination.page,
        qty: this.pagination.limit
      }
      this.$store.dispatch('fetchService', { name: 'getDeliveriesPlat', queryParams, onSuccess: this.handleDeliveryInfo, onError: () => this.loading.deliveries = false})
    },
    orderBy (sortAs, type) {
    },
    handleDeliveryInfo () {
      if (!this.$refs.refModalSearch) return
      this.$refs.refModalSearch.resetForm()
    },
    goTo (id) {
      window.open(`${this.environment}/beta/deliveries/${id}`, '_blank')
    },
    deleteDeliveries () {
      this.$confirm(
        'Antes de eliminar los envíos se verificará que no esten en movimiento, de ser así los envíos en movimiento no se eliminaran.<br><br>La operación puede tomar varios minutos.',
        () => {
          this.loading.deliveries = true
          this.$loadingAlert({text: 'Eliminando envíos, por favor espere...', color: 'primary'})
          const queryParams = {
            force_tracking: false,
            deliveries: this.selectedRows
          }
          this.$store.dispatch('fetchService', { 
            name: 'deleteDeliveries',
            queryParams,
            onSuccess: () => {
              this.m_closeAlertLoading()
              this.$bvToast.toast(`Envios: ${this.selectedRows} eliminados correctamente`, {
                title: 'Éxito',
                variant: 'success',
                solid: true
              })
              this.filterList(this.formFilter)
            },              
            onError: () => {
              this.loading.deliveries = false
              this.m_closeAlertLoading()
            }
          })
        },
        this.$t('msg-pregunta-eliminar-envios')
      )
    },
    confirmeDelete(id) {
      this.$confirm(
        null,
        () => {
          this.$loadingAlert({text: `Eliminando envío: ${id}, por favor espere...`, color: 'primary'})
          const params = { delivery_id: id }
          const queryParams = { skip_rule: false }
          this.$store.dispatch('fetchService', { 
            name: 'deleteDeliveriesByID',
            params, queryParams,
            onSuccess: () => {
              this.m_closeAlertLoading()
              this.$bvToast.toast(`Envio: ${id} eliminado correctamente.`, {
                title: 'Éxito',
                variant: 'success',
                solid: true
              })
              this.filterList(this.formFilter)
            },
            onError: () => this.m_closeAlertLoading()
          })
        },
        this.$t('msg-pregunta-eliminar-envio')
      )
    },
    putlabel() {
      this.$loadingAlert({text: 'Esta operación puede tomar varios minutos, por favor espere...', color: 'primary'})
      this.loadDispacher = true
      const data = {
        name: 'postDispatchDelivery',
        queryParams: {deliveries: this.selectedRows},
        config: { fullResponseError: true },
        onSuccess: (res) => {
          this.m_closeAlertLoading()
          this.loadDispacher = false
          this.filterList(this.formFilter)
          if (res?.data?.status?.id === 4) {
            this.$warning(
              res.data.status.name,
              () => this.$emit('changeTab', 'rejected'),
              'Ocurrió un error al generar la etiqueta',
              'Continuar'
            )            
          } else {
            this.$success(
              this.$t('msg-exito-generar-etiqueta'),
              () => this.$emit('changeTab', 'print'),
              this.$t('Felicitaciones'),
              'Continuar',
              null, false, null, false
            )
          }
        },
        onError: (error) => {
          this.m_closeAlertLoading()
          this.loadDispacher = false
          if (error.status === 400 || error.status === 403) {
            this.$emit('changeTab', 'rejected')
          }
        }
      }
      this.$store.dispatch('fetchService', data)
    },
    cleanForm () {
      this.formFilter = {
        sameday: false,
        date_range: this.initialDateRange
      }
      this.pagination.page > 1 ? this.pagination.page = 1 : this.filterList(this.formFilter)
      this.$refs.modalAvancedFilterDeliveries.cleanForm(true)
    },
    getElementIndex(array, value) {
      return array.findIndex(field => field.key === value)
    },
    changeTextI18n(countryCode = 'CL', elementName, key) {
      const texts = this.$i18nImport(`${countryCode.toLowerCase()}.es`)[`${countryCode.toLowerCase()}.es`]
      const index = this.schema.findIndex(el => el.key === elementName)
      this.schema[index].label = this.$t(texts[key])
    },
    collectFullFilter (data, isExport) {
      this.advancedFilters = data
      this.filterList({...this.formFilter, ...this.advancedFilters}, isExport)
    },
    openModal (name) {
      this.$bvModal.show(name)
    }
  }
}
</script>

<style lang='scss'>
  .underline-text{
    text-decoration: underline !important;
  }
  .custom-switch-success {
    margin-top: 0.5rem;
    > label {
      font-size: 14px !important;
    }
  }
</style>
